<template>
  <b-form-group
    label="Fotoğraf Yükle"
    label-for="upload_image"
  >
    <b-form-file
      id="upload_image"
      v-model="employee.upload_image"
      placeholder="Bir fotoğraf seçin veya buraya bırakın..."
      drop-placeholder="Fotoğrafı buraya bırakın..."
      browse-text="Fotoğraf Seçin"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormFile,
} from 'bootstrap-vue'

export default {
  name: 'UploadImage',
  components: {
    BFormGroup,
    BFormFile,
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style scoped></style>
