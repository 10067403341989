<template>
  <b-form-group
    label="Özgeçmişi"
    label-for="resume"
  >
    <quill-editor
      id="resume"
      v-model="employee.resume"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'SummaryResume',
  components: {
    BFormGroup,
    quillEditor,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style scoped></style>
