<template>
  <b-form-group
    label="İşten Ayrılma Tarihi"
    label-for="edate"
  >
    <b-form-datepicker
      id="edate"
      v-model="employee.edate"
      v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'Edate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style scoped></style>
