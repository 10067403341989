<template>
  <b-form-group
    label="Doğum Tarihi"
    label-for="birthday"
  >
    <validation-provider
      #default="{ errors }"
      name="Doğum Tarihi"
      rules="required"
    >
      <cleave
        id="birthday"
        v-model="employee.birthday"
        class="form-control"
        :raw="false"
        :options="options.date"
        placeholder="DD-MM-YYYY"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'Birthday',
  components: {
    BFormGroup,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
      options: {
        date: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    }
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
