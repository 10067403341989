<template>
  <b-form-group
    label="İşe Başlama Tarihi"
    label-for="sdate"
  >
    <b-form-datepicker
      id="sdate"
      v-model="employee.sdate"
      v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'Sdate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style scoped></style>
