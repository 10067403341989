<template>
  <b-form-group
    label="TC Kimlik No"
    label-for="tckn"
  >
    <cleave
      id="tckn"
      v-model="employee.tckn"
      class="form-control"
      :raw="false"
      :options="options.tckn"
      placeholder="TC Kimlik No"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Tckn',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        tckn: {
          blocks: [11],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    employee() {
      return this.$store.getters['hrEmployees/getHr_employee']
    },
  },
}
</script>

<style scoped></style>
